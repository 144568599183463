<template>
  <div class="ticket-reserved">
    <div ref="ActionBar" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="起点站：">
          <el-select
            v-model="form.startStations"
            :collapse-tags="true"
            multiple
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in startSelect"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终点站：">
          <el-select
            v-model="form.endStations"
            :collapse-tags="true"
            multiple
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in endSelect"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="发车日期起：">
          <el-date-picker
            v-model="form.departureStartDateTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车日期止：">
          <el-date-picker
            v-model="form.departureEndDateTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            ref="companySelect"
            :clearable="true"
            :company-tree="companyList"
            :valuename="valueAddName"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="车次：">
          <el-input
            v-model="form.vehicleShiftName"
            placeholder="请输入车次"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="预留手机：">
          <el-input
            v-model="form.reservedTicketPhone"
            placeholder="请填写预留手机号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch('button')"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            type="primary"
            size="small"
            @click="onExport"
            >导出</el-button
          >
          <el-button
            v-if="listFind('新建预留')"
            type="default"
            size="small"
            @click="recevedTicket"
            >预留票位</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!--  -->
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      :table-height="tableHeight"
      operation-width="220"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="
              listFind('修改预留') &&
              scope.scopeRow.status == 0 &&
              scope.scopeRow.reservedTicket != 0 &&
              scope.scopeRow.soldTicketNum < scope.scopeRow.reservedTicket
            "
            type="text"
            size="small"
            sort="primary"
            style="color: #00bfbf"
            @click="editReserved(scope.scopeRow)"
            >修改票数</el-button
          >
          <el-button
            v-else-if="listFind('修改预留')"
            type="text"
            size="small"
            sort="primary"
            style="color: #ccc"
            >修改票数</el-button
          >
          <el-button
            v-if="
              scope.scopeRow.status == 0 &&
              listFind('解除预留') &&
              scope.scopeRow.reservedTicket != 0 &&
              scope.scopeRow.soldTicketNum < scope.scopeRow.reservedTicket
            "
            type="text"
            size="small"
            sort="primary"
            style="color: #ff8627"
            @click="unReserved(scope.scopeRow)"
            >解除预留</el-button
          >
          <el-button
            v-else-if="listFind('解除预留')"
            type="text"
            size="small"
            sort="primary"
            style="color: #ccc"
            >解除预留</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <!--  -->
    <workForce
      v-if="showForm"
      ref="reserveForm"
      :popupNum="6"
      :startStation="startSelect"
      :endStation="endSelect"
      @closePopupFun="showForm = false"
      @saveOk="
        () => {
          showForm = false;
          onSearch();
        }
      "
    />
    <el-dialog
      title="修改预留"
      :visible.sync="showChange"
      width="600px"
      @close="$refs.changeForm.close()"
    >
      <reserve-change
        ref="changeForm"
        :rate="rate"
        @cancel="showChange = false"
        @saveOk="
          () => {
            showChange = false;
            onSearch();
          }
        "
      />
    </el-dialog>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import workForce from "../vehicleManage/workforcePopup/index.vue";
import reserveChange from "./reservedChange.vue";
import {
  queryReserveTicketPage,
  startStationAPI,
  endStationAPI,
  unReservedTicket,
  getCompanyTree,
  ticketRegulationAPI,
  exportReserveTicketPage,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
    workForce,
    reserveChange,
  },
  data() {
    return {
      titleName: [
        {
          title: "车次",
          props: "vehicleShiftName",
        },
        {
          title: "发车日期",
          props: "departureDateTime",
          SpecialJudgment: (res) => res.substr(0, 10),
          width: 120,
        },
        {
          title: "发车时间",
          props: "departureTime",
        },
        {
          title: "线路",
          // props: "lineName",//主线路
          SpecialJudgment: (res) => `${res.startStation}-${res.endStation}`,
          width: 130,
        },
        {
          title: "上车点",
          props: "startAddress",
          width: 150,
        },
        {
          title: "下车点",
          props: "endAddress",
          width: 150,
        },
        {
          title: "预留票数",
          props: "reservedTicket",
        },
        {
          title: "预留携童票",
          props: "carryingChildrenNum",
          width: 100,
        },
        {
          title: "预留人",
          props: "reservedTicketPassenger",
        },
        {
          title: "预留手机号",
          props: "reservedTicketPhone",
          width: 130,
        },
        {
          title: "预留密码",
          props: "reservedTicketPassword",
        },
        {
          title: "状态",
          props: "status",
          SpecialJudgment: (val) => {
            return val == 0 ? "正常" : val == 1 ? "解除" : val;
          },
        },
        {
          title: "已购票数",
          props: "soldTicketNum",
          SpecialJudgment: (val) => val ?? 0,
        },
        {
          title: "已购携童票",
          props: "soldCarryingChildrenNum",
          width: 100,
        },
        // {
        //   title: "票价",
        //   props: "ticketPrice",
        // },
        // {
        //   title: "预留票金额",
        //   props: "reservedTicketPrice",
        //   width: 100,
        // },
        // {
        //   title: "已购票金额",
        //   width: 100,
        //   props: "purchasedTicketPrice",
        // },
        // {
        //   title: "未购票金额",
        //   props: "unPurchasedTicketPrice",
        //   width: 100,
        // },
        {
          title: "司机名称",
          props: "driverName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
          width: 100,
        },
        {
          title: "备注",
          props: "remark",
        },
        {
          title: "预留时间",
          props: "createTime",
          width: 150,
        },
        {
          title: "所属公司",
          props: "companyName",
        },
      ],
      startSelect: [],
      endSelect: [],
      tableData: [],
      companyList: [],
      valueAddName: "",
      valueAdd: 0,
      form: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        passengerPhone: "",
        reservedTicketPhone: null,
        startStations: [],
        endStations: [],
        departureStartDateTime: "",
        departureEndDateTime: "",
        orderStatus: "",
        companyIds: [],
      },
      tableHeight: 600,
      showChange: false,
      showForm: false,
      rate: 0,
    };
  },
  created() {
    this.getCompanyList();
  },
  mounted() {
    this.computeHeight();
    this.onSearch();
    this.selectdataAPIFun();
    this.renderDataA();
  },
  methods: {
    // 购票规则数据获取
    renderDataA() {
      ticketRegulationAPI({ channelNo: this.$configs.channelNo }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            const value = res.data.find(
              (e) => e.regulationCode == "isCarryingChildren"
            ).regulationValue;
            this.rate = value / 100;
          }
        }
      );
    },
    // 计算表格高度
    computeHeight() {
      const ActionBarHeight = this.$refs.ActionBar.clientHeight;
      this.TableHeight = `calc(100vh - ${ActionBarHeight + 280}px)`;
    },
    // 起终点下拉数据获取
    selectdataAPIFun() {
      startStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.startSelect = [];
          res.data.map((item, index) => {
            this.startSelect.push({ value: index, label: item });
          });
        }
      });
      endStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.endSelect = [];
          res.data.map((item, index) => {
            this.endSelect.push({ value: index, label: item });
          });
        }
      });
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 获得企业id
    getAddGroupId(v) {
      if (v !== null) {
        this.form.companyIds = [v];
      } else {
        this.form.companyIds = [];
      }
    },
    //预留票位
    recevedTicket() {
      this.showForm = true;
      this.$nextTick(() => {
        this.$refs.reserveForm?.loadInfo();
      });
    },
    // 修改票数
    editReserved(row) {
      this.showChange = true;
      this.$nextTick(() => {
        this.$refs.changeForm?.loadInfo(row);
      });
    },
    unReserved(row) {
      console.log(row);
      this.$confirm(
        "解除预留后，乘客将无法通过快客码上车购票，是否继续？",
        "解除预留",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          unReservedTicket({
            id: row.id,
            reservedTicket: row.reservedTicket,
            shiftIntercityId: row.shiftIntercityId,
          }).then((res) => {
            this.$message.success("操作成功");
            this.onSearch();
          });
        })
        .catch(() => {});
    },

    onSearch(type) {
      if (type === "button") {
        this.form.currentPage = 1;
      }
      queryReserveTicketPage(this.form).then((res) => {
        if (res.code == "SUCCESS" && res.data) {
          this.form.total = res.data.total;
          this.tableData = res.data.list ?? [];
        }
      });
    },
    onExport() {
      exportReserveTicketPage(this.form).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "预留记录 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },

    // 当前分页改变
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.onSearch();
    },
    // 分页大小改变
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.onSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-reserved {
  background: hsl(0, 0%, 100%);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  .header {
    padding: 10px;
  }
}
</style>
