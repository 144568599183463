var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reserve-change" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "95px",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "类型：", prop: "isAdd" } }, [
            _c("div", { staticClass: "addReduce" }, [
              _c(
                "div",
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: true },
                      on: { change: _vm.changeIsAdd },
                      model: {
                        value: _vm.ruleForm.isAdd,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isAdd", $$v)
                        },
                        expression: "ruleForm.isAdd",
                      },
                    },
                    [_vm._v("增加预留")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "reduce" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: false },
                      on: { change: _vm.changeIsAdd },
                      model: {
                        value: _vm.ruleForm.isAdd,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isAdd", $$v)
                        },
                        expression: "ruleForm.isAdd",
                      },
                    },
                    [_vm._v("减少预留")]
                  ),
                  _c("div", [_vm._v("减少至0时，自动解除预留")]),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "预留票数量：", prop: "reservedTicket" },
                    },
                    [
                      _vm.ruleForm.isAdd == true || _vm.ruleForm.isAdd == null
                        ? _c("el-input-number", {
                            attrs: {
                              min: 0,
                              max: _vm.surplusTicket,
                              size: "small",
                            },
                            model: {
                              value: _vm.ruleForm.reservedTicket,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "reservedTicket", $$v)
                              },
                              expression: "ruleForm.reservedTicket",
                            },
                          })
                        : _c("el-input-number", {
                            attrs: {
                              min: 0,
                              max:
                                _vm.reservedTicket -
                                _vm.soldTicketNum -
                                _vm.carryingChildrenNum +
                                _vm.ruleForm.carryingChildrenNum,
                              size: "small",
                            },
                            on: { change: _vm.changeReservedTicket },
                            model: {
                              value: _vm.ruleForm.reservedTicket,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "reservedTicket", $$v)
                              },
                              expression: "ruleForm.reservedTicket",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm.showTip
                    ? _c("p", { staticClass: "sign" }, [
                        _vm._v(" 若要继续减少预留数量，请先减少携童数 "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "携童数：" } },
                    [
                      _vm.ruleForm.isAdd == true || _vm.ruleForm.isAdd == null
                        ? _c("el-input-number", {
                            attrs: {
                              min: 0,
                              max:
                                _vm.children > _vm.ruleForm.reservedTicket
                                  ? _vm.ruleForm.reservedTicket
                                  : _vm.children,
                              size: "small",
                            },
                            model: {
                              value: _vm.ruleForm.carryingChildrenNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "carryingChildrenNum",
                                  $$v
                                )
                              },
                              expression: "ruleForm.carryingChildrenNum",
                            },
                          })
                        : _c("el-input-number", {
                            attrs: {
                              min: 0,
                              max: _vm.carryingChildrenNum,
                              size: "small",
                            },
                            on: { change: _vm.changeCarryingChildrenNumTicket },
                            model: {
                              value: _vm.ruleForm.carryingChildrenNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "carryingChildrenNum",
                                  $$v
                                )
                              },
                              expression: "ruleForm.carryingChildrenNum",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showTip2
            ? _c("p", { staticClass: "tip" }, [_vm._v("请减少预留票的票数")])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "备注：" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: "4" },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right", padding: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }