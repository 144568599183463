<template>
  <div class="reserve-change">
    <el-form ref="form" :model="ruleForm" :rules="rules" label-width="95px">
      <el-form-item label="类型：" prop="isAdd">
        <div class="addReduce">
          <div>
            <el-radio
              v-model="ruleForm.isAdd"
              :label="true"
              @change="changeIsAdd"
              >增加预留</el-radio
            >
          </div>
          <div class="reduce">
            <el-radio
              v-model="ruleForm.isAdd"
              :label="false"
              @change="changeIsAdd"
              >减少预留</el-radio
            >
            <div>减少至0时，自动解除预留</div>
          </div>
        </div>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="预留票数量：" prop="reservedTicket">
            <el-input-number
              v-if="ruleForm.isAdd == true || ruleForm.isAdd == null"
              v-model="ruleForm.reservedTicket"
              :min="0"
              :max="surplusTicket"
              size="small"
            ></el-input-number>
            <el-input-number
              v-else
              v-model="ruleForm.reservedTicket"
              :min="0"
              :max="
                reservedTicket -
                soldTicketNum -
                carryingChildrenNum +
                ruleForm.carryingChildrenNum
              "
              size="small"
              @change="changeReservedTicket"
            ></el-input-number>
          </el-form-item>
          <p v-if="showTip" class="sign">
            若要继续减少预留数量，请先减少携童数
          </p>
        </el-col>
        <el-col :span="12">
          <el-form-item label="携童数：">
            <el-input-number
              v-if="ruleForm.isAdd == true || ruleForm.isAdd == null"
              v-model="ruleForm.carryingChildrenNum"
              :min="0"
              :max="
                children > ruleForm.reservedTicket
                  ? ruleForm.reservedTicket
                  : children
              "
              size="small"
            ></el-input-number>
            <el-input-number
              v-else
              v-model="ruleForm.carryingChildrenNum"
              :min="0"
              :max="carryingChildrenNum"
              size="small"
              @change="changeCarryingChildrenNumTicket"
            ></el-input-number> </el-form-item
        ></el-col>
      </el-row>
      <p v-if="showTip2" class="tip">请减少预留票的票数</p>
      <el-form-item label="备注：">
        <el-input type="textarea" rows="4" v-model="ruleForm.remark"></el-input>
      </el-form-item>
      <div style="text-align: right; padding: ">
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { updateReservedTicket, intercityAPI } from "@/api/lib/api.js";
export default {
  props: ["rate"],
  data() {
    return {
      reservedTicket: 0,
      carryingChildrenNum: 0,
      ruleForm: {
        reservedTicket: 0,
        isAdd: null,
        remark: "",
        carryingChildrenNum: 0,
      },
      showTip: false,
      showTip2: false,
      rules: {
        isAdd: [{ required: true, message: "类型不能为空" }],
        reservedTicket: [
          { required: true, message: "数量不能为空" },
          {
            validator: (rules, value, callback) => {
              if (value == 0) return callback(new Error("增减数量不能为0"));
              return callback();
            },
            trigger: "blur",
          },
        ],
      },
      surplusTicket: 0,
      children: 0,
      soldTicketNum: 0,
    };
  },
  methods: {
    loadInfo(info) {
      this.ruleForm.id = info.id;
      this.ruleForm.shiftIntercityId = info.shiftIntercityId;
      this.$set(this.ruleForm, "remark", info.remark);
      this.reservedTicket = info.reservedTicket;
      this.soldTicketNum = info.soldTicketNum ?? 0;
      this.carryingChildrenNum = info.carryingChildrenNum;
      intercityAPI(info.shiftIntercityId).then((res) => {
        if (res.code == "SUCCESS") {
          this.surplusTicket = res.data.surplusTicket;
          this.children =
            Math.floor(res.data.ticketNum * this.rate) -
            info.carryingChildrenNum;
        }
      });
    },
    close() {
      this.ruleForm = {
        reservedTicket: 0,
        isAdd: null,
        remark: "",
        carryingChildrenNum: 0,
      };
      this.$refs.form.resetFields();
      this.showTip = false;
      this.showTip2 = false;
      this.$emit("cancel");
    },
    changeIsAdd() {
      this.ruleForm.reservedTicket = 0;
      this.ruleForm.carryingChildrenNum = 0;
      if (
        !this.ruleForm.isAdd &&
        this.reservedTicket == this.carryingChildrenNum
      )
        this.showTip = true;
      else {
        this.showTip = false;
      }
    },
    changeReservedTicket(cur, old) {
      //点击'-'号
      if (cur < old && old == this.ruleForm.carryingChildrenNum) {
        this.showTip = true;
        this.$nextTick(() => {
          this.ruleForm.reservedTicket = this.ruleForm.carryingChildrenNum;
        });
      }
      //点击'+'号
      if (cur > old) {
        this.showTip = false;
      }
      if (this.ruleForm.carryingChildrenNum < this.ruleForm.reservedTicket)
        this.showTip = false;
      this.changeCarryingChildrenNumTicket();
    },
    changeCarryingChildrenNumTicket() {
      if (this.ruleForm.isAdd == false) {
        if (
          this.reservedTicket -
            this.ruleForm.reservedTicket -
            this.soldTicketNum <
          this.carryingChildrenNum - this.ruleForm.carryingChildrenNum
        ) {
          this.showTip2 = true;
        } else {
          this.showTip2 = false;
        }
      } else {
        this.showTip2 = false;
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          updateReservedTicket(this.ruleForm).then((res) => {
            this.$message.success("操作成功");
            this.$emit("saveOk");
            this.ruleForm = {
              reservedTicket: 0,
              isAdd: null,
              remark: "",
              carryingChildrenNum: 0,
            };
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reserve-change {
  padding-right: 20px;
  .addReduce {
    display: flex;
    .reduce {
      margin-left: 40px;
      div {
        font-size: 12px;
        line-height: 12px;
        color: rgb(177, 177, 177);
      }
    }
  }
  .tip {
    color: red;
    margin-bottom: 20px;
    text-align: center;
  }
  .sign {
    color: red;
    margin-top: -22px;
    margin-bottom: 6px;
  }
}
</style>
