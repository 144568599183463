var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ticket-reserved" },
    [
      _c(
        "div",
        { ref: "ActionBar", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "85px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "起点站：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": true,
                        multiple: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.startStations,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startStations", $$v)
                        },
                        expression: "form.startStations",
                      },
                    },
                    _vm._l(_vm.startSelect, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "终点站：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": true,
                        multiple: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.endStations,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endStations", $$v)
                        },
                        expression: "form.endStations",
                      },
                    },
                    _vm._l(_vm.endSelect, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发车日期起：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.form.departureStartDateTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "departureStartDateTime", $$v)
                      },
                      expression: "form.departureStartDateTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发车日期止：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.form.departureEndDateTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "departureEndDateTime", $$v)
                      },
                      expression: "form.departureEndDateTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属公司：" } },
                [
                  _c("companySelect", {
                    ref: "companySelect",
                    attrs: {
                      clearable: true,
                      "company-tree": _vm.companyList,
                      valuename: _vm.valueAddName,
                      value: _vm.valueAdd,
                    },
                    on: { getValue: _vm.getAddGroupId },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车次：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入车次", clearable: "" },
                    model: {
                      value: _vm.form.vehicleShiftName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "vehicleShiftName", $$v)
                      },
                      expression: "form.vehicleShiftName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预留手机：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请填写预留手机号" },
                    model: {
                      value: _vm.form.reservedTicketPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reservedTicketPhone", $$v)
                      },
                      expression: "form.reservedTicketPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.onSearch("button")
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                  _vm.listFind("导出")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.onExport },
                        },
                        [_vm._v("导出")]
                      )
                    : _vm._e(),
                  _vm.listFind("新建预留")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "default", size: "small" },
                          on: { click: _vm.recevedTicket },
                        },
                        [_vm._v("预留票位")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Table", {
        attrs: {
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          operation: true,
          "col-class-type": true,
          "table-height": _vm.tableHeight,
          "operation-width": "220",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  [
                    _vm.listFind("修改预留") &&
                    scope.scopeRow.status == 0 &&
                    scope.scopeRow.reservedTicket != 0 &&
                    scope.scopeRow.soldTicketNum < scope.scopeRow.reservedTicket
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#00bfbf" },
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editReserved(scope.scopeRow)
                              },
                            },
                          },
                          [_vm._v("修改票数")]
                        )
                      : _vm.listFind("修改预留")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#ccc" },
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                          },
                          [_vm._v("修改票数")]
                        )
                      : _vm._e(),
                    scope.scopeRow.status == 0 &&
                    _vm.listFind("解除预留") &&
                    scope.scopeRow.reservedTicket != 0 &&
                    scope.scopeRow.soldTicketNum < scope.scopeRow.reservedTicket
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#ff8627" },
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.unReserved(scope.scopeRow)
                              },
                            },
                          },
                          [_vm._v("解除预留")]
                        )
                      : _vm.listFind("解除预留")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#ccc" },
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                          },
                          [_vm._v("解除预留")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.form.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.showForm
        ? _c("workForce", {
            ref: "reserveForm",
            attrs: {
              popupNum: 6,
              startStation: _vm.startSelect,
              endStation: _vm.endSelect,
            },
            on: {
              closePopupFun: function ($event) {
                _vm.showForm = false
              },
              saveOk: () => {
                _vm.showForm = false
                _vm.onSearch()
              },
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "修改预留", visible: _vm.showChange, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.showChange = $event
            },
            close: function ($event) {
              return _vm.$refs.changeForm.close()
            },
          },
        },
        [
          _c("reserve-change", {
            ref: "changeForm",
            attrs: { rate: _vm.rate },
            on: {
              cancel: function ($event) {
                _vm.showChange = false
              },
              saveOk: () => {
                _vm.showChange = false
                _vm.onSearch()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }